import React, { Component } from "react";
import {
  Button,
  Header,
  Icon,
  Message,
  Segment, 
  Table,
  Divider,
  Confirm,
  Grid,
  Input
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"; 
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
const { REACT_APP_API_URL } = process.env;
const customStyles = {
  rows: {
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};

class PricingPlan extends Component {
  state = {
    offset: 0,
    currentPage: 1,
    dataPerPage: 10,
    searchTerm: "",
    pricingPlans: []
  }
  constructor(props) {
    super(props);
  }
  pricingPlans = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let pricingPlans = await axios.get(`${REACT_APP_API_URL}/pricing-plans?site=${site}&singlePaymentUnlimited=false`);
      this.setState({ pricingPlans: pricingPlans.data });
    } catch (err) {

    }
  }
  componentDidMount = async () => {
    await this.pricingPlans();
  }

  render() {
    const { todos, currentPage, dataPerPage, searchTerm } = this.state;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentTodos = this.state.pricingPlans.slice(indexOfFirstData, indexOfLastData);
    var data = [...currentTodos].filter((value) => {
      if (searchTerm == "") {
        return value
      }
      else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


    var columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Currency',
        selector: 'pricing.price.currency',
        sortable: true,
        center: true
      },
      {
        name: 'Price',
        selector: 'pricing.price.value',
        sortable: true,
        center: true
      },
      {
        name: 'Tagline',
        cell: row => row.description ? row.description : '(empty)',
        sortable: true,
        center: true
      },
      {
        name: 'Created at',
        cell: row => <span>{moment(row.createdAt).format('lll')}</span>,
        sortable: true,
        center: true
      },
      {
        name: 'Updated at',
        cell: row => <span>{moment(row.updatedAt).format('lll')}</span>,
        sortable: true,
        center: true
      },
    ];
    if (!this.state.pricingPlans) {
      return <Message>Pricing Plans Loading ...</Message>;
    }
    return (
      <div>

        <Segment>
          <div className="row mb-4 mt-2">
            <div className="col">
              <h2>
                Pricing Plans
              </h2>
            </div>
            <div className="col">
              <div className="d-flex w-100 justify-content-end">
                <div>
                  <Input type="text" size='mini' icon='search' placeholder="search.... " onChange={event => { this.setState({ searchTerm: event.target.value }) }} />
                </div>
              </div>
            </div>
          </div>

          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
          />
        </Segment>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(this.state.pricingPlans.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handleClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} />
      </div>
    );
  }
}


export default PricingPlan;
